<template>
  <Toast/>
  <div :class="containerClass" @click="onWrapperClick">

    <div class="layout-form-container">
      <div class="layout-main">
        <Card>
          <template #title>
            Create New Account
          </template>
          <template #content>
            <div class="grid p-fluid">
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <span class="p-float-label">
                    <InputText id="accountName" type="text" v-model="accountName"/>
                    <label for="accountName">Account Name</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="grid p-fluid">
              <div class="col-12 md:col-4">
                <div class="field">
                  <label for="slaveUrl">Slave Url</label>
                  <InputText id="slaveUrl" type="slaveUrl" aria-describedby="slaveUrlHelp"
                             v-model="slaveUrl"></InputText>
                  <small id="slaveUrlHelp">Slave Url for remote machine. Ask Dev to create one</small>
                </div>
              </div>
            </div>
          </template>

          <template #footer>
            <Button icon="pi pi-check" label="Create" @click="createAccount()"/>

            <Button icon="pi pi-times" label="Cancel" class="p-button-secondary" style="margin-left: .5em"
                    @click="cancelCreate()"/>
          </template>
        </Card>

      </div>
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import AccountService from "@/service/AccountService";
import {creds} from "@/states/Cred";

export default {
  name: "CreateAccount",
  created() {
    this.accountService = new AccountService();
  },
  data() {
    return {
      creds,
      accountService: null,
      accountName: null,
      slaveUrl: null
    }
  },

  methods: {
    createAccount() {
      const request = {
        "accountName": this.accountName,
        "slaveUrl": this.slaveUrl
      }
      this.accountService.createAccount(request)
          .then(res => {
            if (res.status !== 200) {
              this.$toast.add({
                severity: 'error',
                summary: 'Account Creation Failed',
                detail: res.status + " " + res.data.detail,
                life: 3000
              });
            } else {
              this.$toast.add({
                severity: 'success',
                summary: 'Account Created',
                life: 3000
              });

              this.$emit('accountChanged', event)
              router.push("/")
            }
          });
    },

    cancelCreate() {
      router.push("/")
    }
  },
  components: {
  }

}
</script>

<style scoped>

</style>
